<!--
  Ghir 9issssssssssssssssssssssso hadiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii
  :=)
-->
<template>
  <div>
    <div
      class="uploadImageId relative w-64 h-64 bg-gray-200 border border-solid border-gray-300  overflow-hidden"
      :id="'uploadImageId-' + Math.random()"
    >
      <img
        :src="$checkIcons(paramFile, type)"
        :onerror="`javascript:this.src='/no-${type}.png'`"
        class="h-64 w-64 object-cover opacity-40"
        id="avatar_upload"
      />
      <button
        class="bg-dokBlue-lighter absolute border-0 bottom-0 top-0 h-full w-full animate__bounceIn"
        v-if="activeUpload"
      >
        <span class="text-dokBlue-ultra"
          ><i class="fas fa-file-upload fa-3x"></i
        ></span>
        <input
          type="file"
          title="Upload Avatar"
          class="absolute left-0 right-0 top-0 bottom-0 h-full w-full cursor-pointer opacity-0"
          @change="setAvatar"
        />
      </button>
    </div>
    <!-- Modal For Crop Image -->
    <modal
      :isActive="modal"
      :exitModal="
        () => {
          modal = false;
        }
      "
      title=""
    >
      <div class="px-2">
        <cropper
          :src="img"
          @change="cropImg"
          :stencil-props="{
            previewClass: 'preview'
          }"
          stencil-component="circle-stencil"
        />
      </div>

      <div class="flex justify-end p-2">
        <button
          @click="uploadAvatarToServer"
          class="border-2 border-solid border-dokBlue-ultra text-dokBlue-ultra bg-transparent py-3 px-5 font-EffraR rounded-cu cursor-pointer hover:bg-dokBlue-ultra hover:text-white"
        >
          Sauvegarder
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

// Crop image
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

// Modal
import modal from "@/views/global-components/modal";

export default {
  components: { modal, Cropper },
  data() {
    return {
      imageInformation: {}, // File Information
      binryFile: null, // Final File
      img: null, // Prview Image Crop
      modal: false // Modal Crop
    };
  },
  props: {
    paramFile: {
      type: String,
      required: false
    },
    activeUpload: {
      type: Boolean
    },
    runSaveFunction: {
      type: Function,
      required: false
    },
    type: {
      type: String,
      required: true,
      default: "stamp"
    }
  },
  methods: {
    ...mapActions("user", ["UPDATE_AVATAR"]),
    bloobToFile(dataurl) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], this.imageInformation.name, { type: mime });
    },
    async cropImg({ coordinates, canvas, image }) {
      this.binryFile = this.bloobToFile(canvas.toDataURL());
    },
    async setAvatar() {
      let avatar = document.getElementById("avatar_upload");

      let { mimes, fileType } = {
        mimes: ["image/gif", "image/jpeg", "image/png", "application/pdf"],
        fileType: event.target.files[0]["type"]
      };

      if (!mimes.includes(fileType)) return alert("Canont be upload this");

      this.imageInformation = event.target.files[0];
      this.img = URL.createObjectURL(event.target.files[0]);
      if (fileType == "application/pdf") {
        this.binryFile = event.target.files[0];
        this.uploadAvatarToServer();
      } else {
        this.modal = true;
      }
    },
    async uploadAvatarToServer() {
      this.$vs.loading();
      const reponseUploadFile = await this.UPDATE_AVATAR({
        file: this.binryFile
      });
      this.onData(reponseUploadFile);
    },
    onData({ ok, fileName }) {
      if (ok) {
        this.$emit("update:paramFile", fileName);

        if (typeof this.runSaveFunction !== "function") {
          this.$vs.notify({
            time: 4000,
            text: ok
              ? "Téléchargement de l'image effectué avec succès"
              : "Erreur de téléchargement de l'image",
            color: ok ? "success" : "danger",
            iconPack: "feather",
            icon: ok ? "icon-check" : "icon-close"
          });
        }

        if (typeof this.runSaveFunction === "function") this.runSaveFunction();

        this.modal = false;
      }
      this.$vs.loading.close();
    }
  }
};
</script>

<style lang="scss">
.opacity-40 {
  opacity: 0.4;
}
.uploadImageId {
  button {
    @apply hidden;
  }

  &:hover {
    button {
      @apply block;
    }
  }
}

.preview {
  @apply border border-dashed border-dokBlue-ultra;
}
</style>
